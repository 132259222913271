<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import CustomerLayout from '@/Layouts/CustomerLayout.vue';
import { Head, usePage } from '@inertiajs/vue3';

import { locale } from '@/Localization.js';

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
});

const subscriber = usePage().props.auth.subscriber;
// const last_subscription_expires_date = usePage().props.auth.last_subscription_expires_date;
// const last_subscription_is_expired = usePage().props.auth.last_subscription_is_expired;
// const last_subscription_is = usePage().props.auth.last_subscription_is;

if (subscriber) {
    window.location.href = route('customer.app.index', { locale: locale.value })
} else {
    window.location.href = `https://www.meditbe.com/${locale.value}/`;
}
</script>
<template>

    <Head title="Bienvenue" />

    <CustomerLayout :nav="false">
        <!-- <div class="text-xl text-gray-800 dark:text-gray-200 leading-tight">
            Bonjour !
        </div> -->

        <ApplicationLogo class="dark:invert-0 mt-16 block h-9 mx-auto w-auto fill-current text-gray-800 dark:text-gray-200 animate-bounce" />

        <!-- <pre>last_subscription_expires_date: {{ last_subscription_expires_date }}</pre>
        <pre>last_subscription_is_expired: {{ last_subscription_is_expired }}</pre>
        <pre>last_subscription_is: {{ last_subscription_is }}</pre>
        <pre>subscriber: {{ subscriber }}</pre> -->

    </CustomerLayout>
</template>